import {Trans, useTranslation} from "react-i18next";
import {useEffect, useMemo} from "react";
import "./Home.css";
import ImageSlider from "../../components/imageSlider";
import SemiCircle from "../../components/semicircle";
import BenefitBlock from "./benefitBlock";
import VideoPlayer from "./miniVideoPlayer";
import useAnimation from "../../utils/hooks/animate";
import {NavLink} from "react-router-dom";

const images = [
    "/images/1.png",
    "/images/2.png",
    "/images/3.png",
    "/images/4.png",
    "/images/5.png",
];

export const Home = () => {
    const {t, i18n} = useTranslation();
    useAnimation();
    const labels = useMemo(
        () => [
            t("home.slider.1"),
            t("home.slider.2"),
            t("home.slider.3"),
            t("home.slider.4"),
            t("home.slider.5"),
        ],
        [t]
    );
    useEffect(() => {
        document.title = t("title", {name: ""});
    }, [t]);

    return (
        <main>
            <div className="position-absolute top-0 start-0 home-text-background">
                <div className="home-text-background part1">
                    {t("home.background.1")}
                </div>
                <div className="home-text-background part2 offset-lg-5 col-lg-6">
                    {t("home.background.2")}
                </div>
            </div>

            {/* Платформа для приложений */}

            <div className="d-flex flex-column flex-lg-row section-platform">
                <div className="d-flex flex-column col-lg-6 me-lg-4 position-relative mb-3 mb-lg-0">
                    <div className="d-flex flex-sm-row flex-column">
                        <h2 className="text-header">{t("home.header.1.1")}</h2>
                        <div
                            className="dash"
                            id="dash_1"
                            style={{height: "1.1rem"}}
                        ></div>
                    </div>
                    <h2 className="text-header">{t("home.header.1.2")}</h2>
                </div>
                <div className="col-8 col-lg-6 d-flex flex-lg-row gap-3 gap-lg-3 flex-column platform-p-row mb-lg-0">
                    <p className="col pe-lg-4">{t("home.p.1")}</p>
                    <p className="col pe-lg-5">{t("home.p.2")}</p>
                </div>
            </div>
            <ImageSlider images={images} labels={labels} className="mx-auto mb-5"/>

            {/* Подробнее */}

            <div className="section-details">
                <div
                    className="details-header-container flex-sm-row flex-column d-flex mb-5"
                    style={{width: "fit-content"}}
                >
                    <h2 className="text-header text-center">{t("home.header.2")}</h2>
                    <div className="dash" id="dash_2"></div>
                </div>
                <div className="row mb-xxl-5 mb-4">
                    <h3
                        className="text-header2 col-lg-3 offset-lg-2 offset-xxl-3 mb-4 mb-lg-0"
                        style={{letterSpacing: "0.6rem !important"}}
                    >
                        <Trans i18nKey={"home.header2.1"}>
                            <br/>
                        </Trans>
                    </h3>
                    <p className="col-lg-3 pe-xxl-5 col-9 ">
                        <Trans i18nKey={"home.p.3"}>
                            <br/>
                            <NavLink to={"/ufa"} style={{color: "var(--primary-color)"}}> </NavLink>
                        </Trans>
                    </p>
                </div>
                <div className="row position-relative details-second-row">
                    <p className="col-lg-3 offset-lg-2 offset-xxl-3 mb-lg-0 mb-3 col-9">
                        <Trans i18nKey={"home.p.4"}>
                            <br/>
                        </Trans>
                    </p>
                    <p className="col-lg-3 offset-lg-3 col-9">
                        <Trans i18nKey={"home.p.5"}>
                            <br/>
                        </Trans>
                    </p>
                    <div className="position-absolute back-part-3-4 col-xxl-7 offset-xxl-2">
                        <div className="home-text-background part3">
                            {t("home.background.3")}
                        </div>
                        <div className="home-text-background part4">
                            {t("home.background.4")}
                        </div>
                    </div>
                </div>
            </div>

            {/* Включает */}

            <div className="d-flex flex-lg-row flex-column position-relative section-includes">
                <div className="includes-container">
                    <h2
                        className="text-header home-text-header-includes text-left text-lg-center "
                        style={{fontSize: "4rem", letterSpacing: "0rem"}}
                    >
                        {t("home.header.3")}
                    </h2>
                    <div
                        className="dash position-absolute bottom-0 mb-lg-5"
                        id="dash_3"
                        style={{marginLeft: 0}}
                    ></div>
                    <div className="home-block-1-right">
                        <div className="top-0">
                            <SemiCircle/>
                        </div>
                        <div className="position-absolute bottom-0 d-flex flex-column">
                            <SemiCircle/>
                            <SemiCircle/>
                        </div>
                    </div>
                </div>
                <div className="home-block home-block-1">
                    {Array.from({length: 4}, (_, i) => (
                        <div className="d-flex flex-row gap-5 " key={i}>
                            <label className="text-header2">{i + 1}</label>
                            <p>{t(`home.p.${6 + i}`)}</p>
                        </div>
                    ))}
                    <div
                        className="d-flex flex-row gap-5"
                        style={{gridColumn: "span 2"}}
                    >
                        <label className="text-header2">5</label>
                        <p>{t("home.p.10")}</p>
                    </div>
                </div>
            </div>

            {/* Кому подойдет */}

            <div className="position-relative">
                <div
                    className="d-flex flex-sm-row flex-column mx-lg-auto"
                    style={{width: "fit-content", marginBottom: "5rem"}}
                >
                    <h2 className="text-header">{t("home.header.4")}</h2>
                    <div className="dash" id="dash_3"></div>
                </div>
                <div className="col-xxl-6 col-lg-11 col-sm-12 offset-xxl-3 offset-lg-1 offset-sm-0 d-flex flex-sm-row flex-column fits-first-row">
                    <div className="d-flex flex-column gap-xxl-5 gap-lg-4 gap-2 col-xxl-5 col-lg-3">
                        <h3 className="text-header2">{t("home.header2.2")}</h3>
                        <p>{t("home.p.11")}</p>
                    </div>
                    <div className="d-flex flex-column gap-xxl-5 gap-lg-4 gap-2 col-lg-4">
                        <h3 className="text-header2">{t("home.header2.3")}</h3>
                        <p>{t("home.p.12")}</p>
                    </div>
                </div>
                <div className="w-xxl-100 row mt-lg-5 fits-second-row col-xxl-12 col-lg-11 offset-lg-1 offset-xxl-0">
                    <div className="d-flex flex-column col-lg-3 col-sm-6 gap-xxl-5 gap-lg-4 gap-2 fits-p-4">
                        <h3 className="text-header2">{t("home.header2.4")}</h3>
                        <p>{t("home.p.13")}</p>
                    </div>
                    <div className="offset-xxl-3 col-lg-3 col-sm-5 mt-sm-5 mt-lg-0 d-flex flex-column gap-xxl-5 gap-lg-4 gap-2 fits-p-5">
                        <h3 className="text-header2">{t("home.header2.5")}</h3>
                        <p>{t("home.p.14")}</p>
                    </div>
                    <div className="col-lg-3 d-flex flex-column gap-xxl-5 gap-lg-4 gap-2 fits-p-6">
                        <h3 className="text-header2">{t("home.header2.6")}</h3>
                        <p>{t("home.p.15")}</p>
                    </div>
                </div>
                <div className="position-absolute back-part-5-6">
                    <div className="home-text-background part5">
                        {t("home.background.5")}
                    </div>
                    <div className="home-text-background part6">
                        {t("home.background.6")}
                    </div>
                </div>
            </div>

            {/* Присоединяйтесь к нам */}

            <div className="home-block position-relative section-join">
                <div className="position-absolute home-text-background part7">
                    {t("home.background.7")}
                </div>
                <div className="position-absolute home-text-background part8">
                    {t("home.background.8")}
                </div>
                <div className="position-absolute home-text-background part9">
                    {t("home.background.9")}
                </div>
                <div className="w-100 position-absolute d-flex flex-column join-us-header-container">
                    <div className="d-flex flex-lg-row flex-column col-8 col-lg-12">
                        <div
                            className={`offset-xxl-3 offset-lg-2 ${
                                i18n.language === "ru" ? "col-lg-7" : ""
                            } join-us-header`}
                        >
                            <h2 className="text-header">{t("home.header.5")}</h2>
                        </div>
                        <div
                            className="dash"
                            id="dash_4"
                            style={{marginLeft: "3rem"}}
                        ></div>
                    </div>
                    <p className="offset-xxl-3 offset-lg-2 col-xxl-3 col-lg-4 mt-5 mb-5 col-8">
                        {t("home.p.16")}
                    </p>
                    <NavLink to={"/download"} className="offset-xxl-3 offset-lg-2 rounded-pill p16-btn">{t("home.p.16.btn")}</NavLink>
                </div>
                <div className="position-absolute asterisk-container">
                    <img src="assets/asterisk.svg" alt="" className="asterisk"></img>
                </div>
            </div>

            {/* Преимущества */}

            <div className="section-benefits">
                <div className="d-flex flex-lg-row flex-column justify-content-center benefits-header-container">
                    <div
                        className="dash offset-lg-3"
                        id="dash_5"
                        style={{
                            marginLeft: 0,
                            marginRight: "4rem",
                            transformOrigin: "right",
                        }}
                    ></div>
                    <h2 className="text-header mb-lg-0 mb-5" id="header_6">
                        {t("home.header.6")}
                    </h2>
                </div>
                <div className="home-benefits-grid">
                    <BenefitBlock
                        number={1}
                        title={t("home.benefit.1.title")}
                        description={t("home.benefit.1.description")}
                    />
                    <BenefitBlock
                        number={2}
                        title={t("home.benefit.2.title")}
                        description={t("home.benefit.2.description")}
                    />
                    <BenefitBlock
                        number={3}
                        title={t("home.benefit.3.title")}
                        description={t("home.benefit.3.description")}
                    />
                    <div
                        style={{gridColumn: "1", gridRow: "2"}}
                        className="d-flex flex-column justify-content-center "
                    >
                        <p className="mb-3 pe-lg-0 pe-2 ps-lg-0 ps-2">{t("home.p.17")}</p>
                        <p>
                            <NavLink to={"/download"} style={{color: "#39c1ff"}} className="ps-lg-0 ps-2">{t("home.p.17.btn")}</NavLink>
                        </p>
                    </div>
                    <BenefitBlock
                        className="benefit-block-4"
                        number={4}
                        title={t("home.benefit.4.title")}
                        description={t("home.benefit.4.description")}
                    />
                    <BenefitBlock
                        className="benefit-block-5"
                        number={5}
                        title={t("home.benefit.5.title")}
                        description={t("home.benefit.5.description")}
                    />
                    <BenefitBlock
                        className="benefit-block-6"
                        number={6}
                        title={t("home.benefit.6.title")}
                        description={t("home.benefit.6.description")}
                    />
                    <div className="d-flex flex-column justify-content-end align-items-center benefit-plus-block">
                        <div className="benefit-plus-block-inner">
                            <div className="rounded-pill home-block justify-content-center align-items-center d-flex mb-3 benefit-plus">
                                <p style={{fontSize: "1.8rem"}}>+</p>
                            </div>
                            <p>{t("home.p.18")}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Алгоритм создания приложений */}

            <div className="position-relative" style={{marginBottom: "18rem"}}>
                <div className="d-flex flex-column" style={{marginBottom: "6rem"}}>
                    <div className="d-flex flex-row">
                        <h2
                            className="text-header offset-lg-3 algorithm-header"
                            style={{fontSize: "3.6rem", letterSpacing: "-0.1rem"}}
                        >
                            {t("home.header.7.1")}
                        </h2>
                        <div className="dash" id="dash_6"></div>
                    </div>
                    <h2
                        className="text-header offset-lg-3 algorithm-header"
                        style={{fontSize: "3.6rem", letterSpacing: "-0.1rem"}}
                    >
                        {t("home.header.7.2")}
                    </h2>
                </div>
                <div className="d-flex d-lg-flex d-sm-grid flex-lg-row flex-column algorithm-first-row">
                    <div className="d-flex flex-column col-lg-3 offset-lg-3">
                        <h3
                            className="text-header"
                            style={{
                                fontSize: "1.7rem",
                                letterSpacing: "0",
                                lineHeight: "1.3",
                            }}
                        >
                            <Trans i18nKey={"home.header2.7"}>
                                <br/>
                            </Trans>
                        </h3>
                        <p className="algorithm-p-1" style={{marginTop: "1.8rem"}}>
                            {t("home.p.19")}
                        </p>
                    </div>
                    <div className="d-flex flex-column col-lg-3">
                        <h3
                            className="text-header"
                            style={{
                                fontSize: "1.7rem",
                                letterSpacing: "0",
                                lineHeight: "1.3",
                            }}
                        >
                            <Trans i18nKey={"home.header2.8"}>
                                <br/>
                            </Trans>
                        </h3>
                        <p className="algorithm-p-2" style={{marginTop: "1.8rem"}}>
                            {t("home.p.20")}
                        </p>
                    </div>
                </div>
                <div className="d-flex d-lg-flex d-sm-grid flex-lg-row flex-column algorithm-second-row">
                    <div className="d-flex flex-column col-lg-3 offset-lg-3">
                        <h3
                            className="text-header"
                            style={{
                                fontSize: "1.7rem",
                                letterSpacing: "0",
                                lineHeight: "1.3",
                            }}
                        >
                            <Trans i18nKey={"home.header2.9"}>
                                <br/>
                            </Trans>
                        </h3>
                        <p className="algorithm-p-3" style={{marginTop: "1.8rem"}}>
                            {t("home.p.21")}
                        </p>
                    </div>
                    <div className="d-flex flex-column col-lg-3">
                        <h3
                            className="text-header"
                            style={{
                                fontSize: "1.7rem",
                                letterSpacing: "0",
                                lineHeight: "1.3",
                            }}
                        >
                            <Trans i18nKey={"home.header2.10"}>
                                <br/>
                            </Trans>
                        </h3>
                        <p className="algorithm-p-4" style={{marginTop: "1.8rem"}}>
                            {t("home.p.22")}
                        </p>
                    </div>
                    <div className="d-flex flex-column col-lg-3">
                        <h3
                            className="text-header"
                            style={{
                                fontSize: "1.7rem",
                                letterSpacing: "0",
                                lineHeight: "1.3",
                            }}
                        >
                            <Trans i18nKey={"home.header2.11"}>
                                <br/>
                            </Trans>
                        </h3>
                        <p className="algorithm-p-5" style={{marginTop: "1.8rem"}}>
                            {t("home.p.23")}
                        </p>
                    </div>
                </div>
                <div className="position-absolute w-100 back-part-10-11">
                    <div className="home-text-background part10">
                        {t("home.background.10")}
                    </div>
                    <div className="home-text-background part11">
                        {t("home.background.11")}
                    </div>
                </div>
            </div>

            {/* Видео */}

            <div className="home-player-container">
                <VideoPlayer id={"TEooFyxKMvM"}/>
            </div>
        </main>
    );
};
