import {Trans, useTranslation} from "react-i18next";
import useAnimation from "../../utils/hooks/animate";

import "./Ufa.css";
import {NavLink} from "react-router-dom";

export const Ufa = () => {
    const {t, i18n} = useTranslation();
    useAnimation();

    return (
        <main>
            <div className="offset-lg-2 col-lg-8 ufa-main">
                <p>{t("ufa.p.1")}</p>
                <div className="d-flex flex-sm-row flex-column offset-lg-4 page-header ufa-header">
                    <div className="text-header">{t("ufa.header.1")}</div>
                    <div className="dash ufa-dash" id="ufa_dash_1"/>
                </div>
                <p>{t("ufa.p.2")}</p>
                <div className="d-flex flex-sm-row flex-column offset-lg-4 page-header ufa-header">
                    <div className="text-header">{t("ufa.header.2")}</div>
                    <div className="dash ufa-dash" id="ufa_dash_2"/>
                </div>
                <p>{t("ufa.p.3")}</p>
                <div className="ufa-img-container">
                    <img src={`images/${i18n.language}/ufa.png`} alt="UFA notation"/>
                </div>
                <p className="ufa-p-from-4">{t("ufa.p.4")}</p>
                <p className="ufa-p-from-4">{t("ufa.p.5")}</p>
                <p className="ufa-p-from-4">{t("ufa.p.6")}</p>
                <p className="ufa-p-from-4">
                    <Trans i18nKey={"ufa.p.7"}>
                        <NavLink to={"/documents"} style={{color: "var(--primary-color)"}}> </NavLink>
                    </Trans>
                </p>
            </div>
        </main>
    );
};
