import "./mobileMenu.css";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const MobileMenu = ({
                        isShown,
                        setIsShown,
                    }: {
    isShown: boolean;
    setIsShown: (isShown: boolean) => void;
}) => {
    const {t} = useTranslation();

    return (
        <>
            <button
                type="button"
                className="m-menu-btn"
                onClick={() => setIsShown(!isShown)}
            >
                MENU
            </button>
            <div className={`m-menu-container ${isShown ? "active" : ""}`}>
                <button className="m-menu-btn-close" onClick={() => setIsShown(false)}>
                    <div className="btn-cross" style={{rotate: "45deg"}}/>
                    <div className="btn-cross" style={{rotate: "-45deg"}}/>
                </button>
                <div className="m-menu-nav">
                    {[
                        [t("home"), "/"], // prettier-ignore
                        [t("documents"), "/documents"], // prettier-ignore
                        [t("video"), "/video"], // prettier-ignore
                        [t("contacts"), "/contacts"], // prettier-ignore
                        [t("download"), "/download"], // prettier-ignore
                    ].map(([text, path], i) => (
                        <h2 className="home-text-header" key={i}>
                            <NavLink
                                className={"nav-link"}
                                onClick={() => setIsShown(false)}
                                to={path}
                            >
                                {text}
                            </NavLink>
                        </h2>
                    ))}
                </div>
                <div className="m-menu-icons-container">
                    {[
                        ["/icons/reddit.svg", "https://www.reddit.com/r/smcplatform/", "r/smcplatform"], // prettier-ignore
                        ["/icons/reddit.svg", "mailto:support@smcsystem.ru", "support@smcsystem.ru"], // prettier-ignore
                        ["/icons/reddit.svg", "https://t.me/smcsystem", "t.me/smcsystem"], // prettier-ignore
                        ["/icons/reddit.svg", "https://x.com/smcplatform", "x.com/smcplatform"], // prettier-ignore
                    ].map(([icon, link, alt], i) => (
                        <img
                            src={icon}
                            alt={alt}
                            key={i}
                            onClick={() => window.open(link)}
                            className="m-menu-icon"
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
