import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./Menu.css";
import {useCallback, useEffect, useState} from "react";
import {node, nodes} from "../../utils/selector";
import MobileMenu from "./mobileMenu";
import isInViewport from "../../utils/viewport";

export const Menu = () => {
    const {i18n, t} = useTranslation();

    const [isShown, setIsShown] = useState(true);
    const [isMobileShown, setIsMobileShown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const menuHeader2 = node(".menu-header2");
            const nav = node("nav");
            setIsShown(
                isInViewport(menuHeader2, nav.getBoundingClientRect().height * 3)
            );
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const funcLang = useCallback((e: MouseEvent, key: string) => {
        i18n.changeLanguage(key);
        e.preventDefault();
        e.stopPropagation();
    }, [i18n]);

    useEffect(() => {
        if (!isShown) {
            const nav = node("nav");
            const cloneNav = nav.cloneNode(true);
            const container = node("div.container");
            (cloneNav as Element).classList.add("scrolled");
            container.appendChild(cloneNav);

            const langAnchors = nodes("nav.scrolled a.lang");
            langAnchors.forEach((langAnchor) => {
                langAnchor.addEventListener("click", (e) =>
                    funcLang(e as any, langAnchor.id)
                );
            });

            const mMenuBtn = node("nav.scrolled .m-menu-btn");
            mMenuBtn?.addEventListener("click", () => {
                setIsMobileShown((prev) => !prev);
            });
            const mMenuBtnClose = node("nav.scrolled .m-menu-btn-close");
            mMenuBtnClose?.addEventListener("click", () => {
                setIsMobileShown(false);
            });
        } else {
            const clone = node("nav.scrolled");
            if (clone) {
                clone.classList.add("unscrolled");
                setTimeout(() => clone.remove(), 200);
            }
        }
    }, [isShown, funcLang]);

    useEffect(() => {
        document.body.style.overflowY = isMobileShown ? "hidden" : "auto";
    }, [isMobileShown]);

    return (
        <div>
            <div className="menu-header"/>
            <div className="menu-header2"/>
            <nav className={"navbar navbar-expand-md navbar-light bg-light ng-scope"}>
                <NavLink to={"/"} className="navbar-brand text-color" style={{letterSpacing: "0.5rem"}}>{t("app.name")}</NavLink>
                <ul className="nav navbar-nav ms-auto h4 menu-text desktop">
                    {[
                        ["/", t("home")], // prettier-ignore
                        ["/documents", t("documents")], // prettier-ignore
                        ["/video", t("video")], // prettier-ignore
                        ["/contacts", t("contacts")], // prettier-ignore
                    ].map(([path, text], i) => (
                        <li className="nav-item active me-2" key={i}>
                            <NavLink className={"nav-link "} to={path}>
                                {text}
                            </NavLink>
                        </li>
                    ))}
                    <li className="nav-item active" style={{marginRight: "-1rem"}}>
                        <a
                            className={"nav-link lang"}
                            onClick={(e) => funcLang(e as any, "ru")}
                            href="/"
                            id="ru"
                        >
                            RU
                        </a>
                    </li>
                    <li className="nav-item active me-2">
                        <a
                            className={"nav-link lang"}
                            onClick={(e) => funcLang(e as any, "en")}
                            href="/"
                            id="en"
                        >
                            EN
                        </a>
                    </li>
                    <li className="nav-item active">
                        <NavLink
                            className={"nav-link download rounded-pill "}
                            to="/download"
                            style={{color: "#FFFFFF"}}
                        >
                            {t("downloadBtn")}
                        </NavLink>
                    </li>
                </ul>
                <ul className="mobile nav navbar-nav ms-auto h4 menu-text">
                    <li className="nav-item active" style={{marginRight: "-1rem"}}>
                        <a
                            className={"nav-link lang"}
                            onClick={(e) => funcLang(e as any, "ru")}
                            href="/"
                            id="ru"
                        >
                            RU
                        </a>
                    </li>
                    <li className="nav-item active">
                        <a
                            className={"nav-link lang"}
                            onClick={(e) => funcLang(e as any, "en")}
                            href="/"
                            id="en"
                        >
                            EN
                        </a>
                    </li>
                    <li className="nav-item" style={{zIndex: 10000}}>
                        <MobileMenu isShown={isMobileShown} setIsShown={setIsMobileShown}/>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
