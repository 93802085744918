import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import useAnimation from "../../utils/hooks/animate";
import "./Contacts.css";
import {NavLink} from "react-router-dom";

export const Contacts = () => {
    const {t} = useTranslation();
    useAnimation();
    useEffect(() => {
        document.title = t("title", {name: t("contacts")});
    }, [t]);

    return (
        <main>
            <div className="d-flex flex-row page-header w-100 mb-0 contacts-page-header">
                <div className="d-flex flex-lg-row flex-column contacts-header">
                    <div className="dash" id="contacts_dash_1"></div>
                    <div className="d-flex flex-column">
                        <div className="text-header">{t("contacts")}</div>
                        <div className="contacts-section">
                            {[
                                ["mailto:support@smcsystem.ru", "support@smcsystem.ru"], // prettier-ignore
                                ["https://www.reddit.com/r/Shelfmc/", "Reddit"], // prettier-ignore
                                ["https://t.me/shelfmc", "Telegram"], // prettier-ignore
                                ["https://twitter.com/smcplatform", "Twitter"], // prettier-ignore
                            ].map(([link, name], i) => (
                                <h3 className="text-header2 contacts-link" key={i}>
                                    <a href={link} style={{color: "var(--primary-color)"}}>
                                        {name}
                                    </a>
                                </h3>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="contacts-gray-block"></div>
                <div className="contacts-footer">
                    {Array.from({length: 4}).map((_, i) => (
                        <div key={i} className="contacts-footer-line"></div>
                    ))}
                    <div className="contacts-footer-brand">
                        <NavLink to={"/"} className="navbar-brand text-color">{t("app.name")}</NavLink>
                    </div>
                </div>
            </div>
        </main>
    );
};
