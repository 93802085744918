import {useEffect} from "react";
import {nodes} from "../selector";
import isInViewport from "../viewport";

const useAnimation = () => {
    useEffect(() => {
        nodes(
            `.dash, .text-header, p, .text-header2, .home-block, .circle, .home-text-background, 
      .asterisk-container, .documents-text-background, .video-text-background, .download-text-background`
        ).forEach((elem) => {
            const interval = setInterval(() => {
                if (isInViewport(elem)) {
                    setTimeout(() => {
                        elem.classList.add("animated");
                    }, 250);
                    clearInterval(interval);
                }
            }, 50);
        });
    }, []);
};

export default useAnimation;
