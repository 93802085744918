import {useEffect} from "react";

const useVideo = () => {
    useEffect(() => {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";

        const firstScriptTag = document.getElementsByTagName("script")[0];
        // @ts-ignore
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);
}

export default useVideo;