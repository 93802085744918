import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import "./Documents.css";
import useAnimation from "../../utils/hooks/animate";

export const Documents = () => {
    const {t} = useTranslation();
    useAnimation();
    useEffect(() => {
        document.title = t("title", {name: t("documents")});
    }, [t]);

    return (
        <main>
            <div className="d-flex flex-sm-row flex-column mx-lg-auto page-header">
                <div className="text-header">{t("documents")}</div>
                <div className="dash" id="doc_dash_1"></div>
            </div>
            <div className="position-absolute doc-parts">
                <div className="documents-text-background part1">
                    {t("documents.background.1")}
                </div>
                <div className="documents-text-background part2">
                    {t("documents.background.2")}
                </div>
                <div className="documents-text-background part3">
                    {t("documents.background.3")}
                </div>
            </div>
            <div className="flex-column d-flex w-100 documents-section">
                {[
                    [t("documents.header2.1"), t("documents.header2.1.link"), "install.pdf"], // prettier-ignore
                    [t("documents.header2.2"), t("documents.header2.2.link"), "userGuide.pdf"], // prettier-ignore
                    [t("documents.header2.3"), t("documents.header2.3.link"), "development.pdf"], // prettier-ignore
                    [t("documents.header2.4"), t("documents.header2.4.link"), "administration.pdf"], // prettier-ignore
                    [t("documents.header2.5"), t("documents.header2.5.link"), "api"], // prettier-ignore
                ].map(([headerText, link, linkText], i) => (
                    <div
                        key={i}
                        className="col-lg-8 col-sm-5 col-12 offset-lg-2 d-flex flex-row justify-content-between position-relative"
                    >
                        <h3 className="text-header2 col-lg-4 col-9 col-md-12">
                            {headerText}
                        </h3>
                        <h3 className="text-header2 documents-link">
                            <a href={link} target="_blank" rel="noreferrer" style={{color: "var(--primary-color)"}}>
                                {linkText}
                            </a>
                        </h3>
                    </div>
                ))}
            </div>
        </main>
    );
};
