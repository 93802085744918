import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const getLanguage = () =>
    (typeof navigator === "undefined" && "en") /* || navigator.userLanguage*/ ||
    (navigator.languages &&
        navigator.languages.length &&
        navigator.languages[0]) ||
    navigator.language /* || navigator.browserLanguage || navigator.systemLanguage*/ ||
    "en";
let currentLanguage = getLanguage();
if (currentLanguage && currentLanguage.length > 2)
    currentLanguage = currentLanguage.substr(0, 2);

const allLanguages = ["ru", "en"];
if (!allLanguages.includes(currentLanguage)) currentLanguage = "en";

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: currentLanguage,
        lng: currentLanguage,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/i18n/{{lng}}.json",
        },
    });

export default i18n;
