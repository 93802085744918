const Frame = ({
                   id,
                   title,
                   className,
               }: {
    id: string;
    title: string;
    className?: string;
}) => {
    return (
        <iframe
            className={`video-frame ${className}`}
            src={`https://www.youtube.com/embed/${id}`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    );
};

export default Frame;