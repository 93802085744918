import "./row.css";

const Row = ({label, link, linkText}: { label: string, link: string, linkText: string }) => {
    return (
        <div className="download-row">
            <h3 className="download-row-label text-header2 col-lg-4 col-sm-6 col-9">{label}</h3>
            <a className="download-row-link text-header2 col-lg-4 offset-sm-1 offset-lg-1" href={link} target="_blank" rel="noreferrer">{linkText}</a>
        </div>
    )
}

export default Row;