import {MouseEventHandler, useCallback, useState} from "react";
import "./miniVideoPlayer.css";
import {useTranslation} from "react-i18next";
import VideoPlayer from "../../components/videoPlayer";

const MiniVideoPlayer = ({id}: { id: string }) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const [player, setPlayer] = useState<null | any>(null);

    const onPlayerReady = useCallback((event: any) => {
        setPlayer(event.target);
        const p = event.target;
        // p.playVideo();
        p.mute();
    }, []);

    const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
        if (isOpened) return;

        setIsOpened(true);
        if (player) {
            player.unMute();
            player.seekTo(0, true);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
        setIsOpened(false);
        if (player) player.mute();
        e.preventDefault();
        e.stopPropagation();
    };

    const hide: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (player) player.stopVideo();
        setPlayer(null);
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            className={`player-container ${player === null ? "hidden" : ""} ${isOpened ? "opened" : ""}`}
            onClick={handleOpen}
        >
            <VideoPlayer id={id} onPlayerReady={onPlayerReady} className="mini__video-player"/>
            {isOpened ? (
                <>
                    <button type="button" className="player-btn" onClick={handleClose}>
                        <div className="player-minus"/>
                    </button>
                    <button type="button" className="player-btn-more">
                        <h2 className="text-header2" style={{margin: 0, opacity: 1}}>
                            <a
                                href={`https://youtu.be/${id}`}
                                style={{
                                    textDecoration: "none",
                                    fontSize: "1rem",
                                    color: "#fff",
                                }}
                            >
                                {t("home.video.more.btn")}
                            </a>
                        </h2>
                    </button>
                </>
            ) : (
                <button type="button" className="player-btn" onClick={hide}>
                    <div className="player-cross" style={{rotate: "45deg"}}/>
                    <div className="player-cross" style={{rotate: "-45deg"}}/>
                </button>
            )}
        </div>
    );
};

export default MiniVideoPlayer;
