import "./semicircle.css";

const SemiCircle = () => {

    return (
        <div className="circle">
            <div className="circle-cover"></div>
        </div>
    )
}

export default SemiCircle;