import {Trans, useTranslation} from "react-i18next";
import {useEffect} from "react";
import useAnimation from "../../utils/hooks/animate";

import "./Video.css";
import Frame from "./frame";

export const Video = () => {
    const {t} = useTranslation();
    useAnimation();
    useEffect(() => {
        document.title = t("title", {name: t("video")});
    }, [t]);

    return (
        <main>
            <div className="d-flex flex-sm-row flex-column mx-lg-auto page-header video-header">
                <div className="text-header">{t("video")}</div>
                <div className="dash" id="vid_dash_1"></div>
            </div>
            <div className="flex-column d-flex w-100 video-section gap-5">
                <div className="d-flex flex-sm-row flex-column justify-content-between">
                    <h3 className="text-header2 col-sm-2 col-10">
                        <Trans i18nKey={"video.header2.1"}>
                            <br/>
                        </Trans>
                    </h3>
                    <div className="d-flex flex-lg-row flex-column video-first-row position-relative">
                        <div className="position-absolute vid-parts">
                            <div className="video-text-background part1">1</div>
                            <div className="video-text-background part2">2</div>
                        </div>
                        <Frame
                            id="uhAnq-gm5cQ"
                            title={t("video.title.1.1")}
                            className="position-relative"
                        />
                        <Frame
                            id="hN-Tm3xIaqI"
                            title={t("video.title.1.2")}
                            className="video-first-row-second-frame position-relative"
                        />
                    </div>
                </div>

                {[
                    ["video.header2.11", ["e2yjTgVeEGg", "video.title.11"]], // prettier-ignore
                    ["video.header2.2", ["nfJaxQ_8MdE", "video.title.2"]], // prettier-ignore
                    ["video.header2.12", ["ieEzsmg2aM4", "video.title.12"]], // prettier-ignore
                    ["video.header2.13", ["eT7R_Sp1LWE", "video.title.13"]], // prettier-ignore
                    ["video.header2.3", ["DlGBb8c-Bic", "video.title.3"]], // prettier-ignore
                    ["video.header2.4", ["ODicXEPseus", "video.title.4"]], // prettier-ignore
                    ["video.header2.5", ["H76Rd1IxzMw", "video.title.5"]], // prettier-ignore
                    ["video.header2.6", ["ODicXEPseus", "video.title.6"]], // prettier-ignore
                    ["video.header2.7", ["CsfedLiytdI", "video.title.7"]], // prettier-ignore
                    ["video.header2.8", ["rLAodqud99E", "video.title.8"]], // prettier-ignore
                    ["video.header2.9", ["Lc5cADa23kM", "video.title.9"]], // prettier-ignore
                    ["video.header2.10", ["fm1kNfpHTJQ", "video.title.10"]], // prettier-ignore
                ].map(([i18nKey, [ytId, ytTitle]], i) => (
                    <div
                        className="d-flex flex-sm-row flex-column justify-content-between position-relative"
                        key={i}
                    >
                        <h3 className="text-header2 col-sm-3 col-10">
                            <Trans i18nKey={i18nKey}>
                                <br/>
                            </Trans>
                        </h3>
                        <Frame id={ytId} title={t(ytTitle)}/>
                    </div>
                ))}
            </div>
        </main>
    );
};
