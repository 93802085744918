import {HashRouter, Route, Routes} from "react-router-dom";
import {Menu} from "./components/Menu";
import {Contacts} from "./pages/Contacts";
import {Home} from "./pages/Home";
import {Suspense} from "react";
import {Video} from "./pages/Video";
import {Documents} from "./pages/Documents";
import {Ufa} from "./pages/Ufa";
import {Download} from "./pages/Download";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import useVideo from "./utils/hooks/video";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
    useVideo();
    return (
        <Suspense fallback="loading">
            <HashRouter>
                <div className="bg-light">
                    <div className="container">
                        <ScrollToTop/>
                        <Menu/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/video" element={<Video/>}/>
                            <Route path="/documents" element={<Documents/>}/>
                            <Route path="/contacts" element={<Contacts/>}/>
                            <Route path="/ufa" element={<Ufa/>}/>
                            <Route path="/download" element={<Download/>}/>
                        </Routes>
                    </div>
                </div>
            </HashRouter>
        </Suspense>
    );
}

export default App;
