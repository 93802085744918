import {CSSProperties, useCallback, useEffect, useState} from "react";
import "./imageSlider.css";
import {useTranslation} from "react-i18next";
import {node, nodes} from "../utils/selector";
import {default as sleep} from "../utils/delay";

const states = ["prev2", "prev", "current", "next", "next2"];

const findState = (slide: Element) => {
    var _state: { name: string; index: number };
    slide.classList.forEach((className) => {
        if (!className.startsWith("slide--")) return;
        states.forEach((state, i) => {
            if (!className.endsWith(state)) return;
            _state = {name: state, index: i};
        });
    });
    return _state!;
};

const ImageSlider = ({
                         images,
                         labels,
                         className,
                         style,
                     }: {
    images: string[];
    labels: string[];
    className?: string;
    style?: CSSProperties;
}) => {
    const [current, setCurrent] = useState(0);
    const {t} = useTranslation();
    const [delay] = useState<number>(5000); // Задержка по умолчанию - 5 секунд
    const [isPaused, setIsPaused] = useState<boolean>(false);

    const switchSlide = useCallback((direction: "next" | "prev") => {
        (node(`.switch-${direction}`) as HTMLButtonElement).disabled = true;
        setTimeout(() => {
            if (!(node(`.switch-${direction}`) as HTMLButtonElement).disabled) return;
            (node(`.switch-${direction}`) as HTMLButtonElement).disabled = false;
        }, 250);
        const curDiff = direction === "next" ? 1 : -1;
        const srcDiff = direction === "next" ? 3 : -3;
        const stateDiff = direction === "next" ? -1 : 1;
        const lastState =
            direction === "next" ? states[states.length - 1] : states[0];
        const firstState =
            direction === "next" ? states[0] : states[states.length - 1];

        setCurrent((prev) => (prev + curDiff + images.length) % images.length);
        nodes(".slide").forEach((slide) => {
            const state = findState(slide);

            if (state.name === firstState) {
                slide.classList.remove("slide--animated");
                slide.classList.remove(`slide--${firstState}`);
                (slide as HTMLImageElement).src =
                    images[(current + srcDiff) % images.length];
                slide.classList.add(`slide--${lastState}`);
            } else {
                slide.classList.remove(`slide--${state.name}`);
                slide.classList.add(`slide--${states[state.index + stateDiff]}`);
                slide.classList.add("slide--animated");
            }
        });
    }, [current, images]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                // Выполняем вашу функцию здесь
                switchSlide("next");
            }
        }, delay);

        return () => clearInterval(interval);
    }, [delay, isPaused, switchSlide]);

    const resetInterval = () => {
        setIsPaused(true);
        setTimeout(() => {
            setIsPaused(false);
        }, 10000);
    };

    const next = () => {
        resetInterval();
        switchSlide("next");
    };
    const prev = () => {
        resetInterval();
        switchSlide("prev");
    };

    const handleChooseSlide = async (index: number) => {
        const diff = index - current;
        for (let i = 0; i < Math.abs(diff); i++) {
            if (diff > 0) {
                next();
            } else {
                prev();
            }
            await sleep(400);
        }
    };

    if (!images || images.length <= 1) return <></>;

    return (
        <div className={`${className} position-relative slider`} style={style}>
            <div className="slide slide--prev2">
                <img
                    alt={t("home.screenshotsAlt")}
                    src={images[(images.length - 2) % images.length]}
                    className="w-100"
                />
            </div>
            <div className="slide slide--prev">
                <img
                    alt={t("home.screenshotsAlt")}
                    src={images[(images.length - 1) % images.length]}
                    className="w-100"
                />
            </div>
            <div className="slide slide--current">
                <img alt={t("home.screenshotsAlt")} src={images[0]} className="w-100"/>
            </div>
            <div className="slide slide--next">
                <img
                    alt={t("home.screenshotsAlt")}
                    src={images[1 % images.length]}
                    className="w-100"
                />
            </div>
            <div className="slide slide--next2">
                <img
                    alt={t("home.screenshotsAlt")}
                    src={images[2 % images.length]}
                    className="w-100"
                />
            </div>
            <button className="btn btn-primary switch-next switch" onClick={next}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                    />
                </svg>
            </button>
            <button className="btn btn-primary switch-prev switch" onClick={prev}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                </svg>
            </button>
            <div className="position-absolute slider-footer">
                <div className="bullet-container">
                    {images.map((image, i) => (
                        <button
                            type="button"
                            key={image}
                            className={`bullet ${current === i ? "bullet--active" : ""}`}
                            onClick={() => handleChooseSlide(i)}
                        />
                    ))}
                </div>
                <div className="footer-line"/>
                <div className="position-relative footer-labels">
                    {labels.map((label, i) => (
                        <p
                            className={`footer-label ${
                                current === i ? "footer-label--active" : ""
                            }`}
                            key={i}
                        >
                            {label}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
