import "./benefitBlock.css";

const BenefitBlock = ({
                          number,
                          title,
                          description,
                          style,
                          className,
                      }: {
    number: number;
    title: string;
    description: string;
    style?: React.CSSProperties;
    className?: string;
}) => {
    return (
        <div className={`home-block position-relative ${className}`} style={style}>
            <p className="position-absolute benefit-number">0{number}</p>
            <div className="position-absolute benefit-paragraph">
                <h2 className="text-header2">{title}</h2>
                <p className="text-wrap overflow-hidden">{description}</p>
            </div>
        </div>
    );
};

export default BenefitBlock;
