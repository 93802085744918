import {useEffect} from "react";

import "./videoPlayer.css";

const VideoPlayer = ({
                         id,
                         onPlayerReady,
                         className,
                     }: {
    id: string;
    onPlayerReady?: (event: any) => void;
    className?: string;
}) => {
    useEffect(() => {
        // @ts-expect-error youtube api is not typed
        window.onYouTubeIframeAPIReady = () => {
            // @ts-expect-error youtube api is not typed
            new YT.Player(id, {
                videoId: id,
                playerVars: {
                    referrerPolicy: "strict-origin-when-cross-origin",
                    allowfullscreen: "",
                    allow:
                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                    controls: 0,
                    enablejsapi: 1,
                    widgetid: 1,
                    loop: 1,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        };
    }, [id, onPlayerReady]);

    return <div id={id} className={`video-player ${className}`}></div>;
};

export default VideoPlayer;
